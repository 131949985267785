import * as React from 'react';
import { ISVGProps } from './facebook';

function Instagram({ title, description, ...props }: ISVGProps) {
  const labelId = title && `svg${title.replace(/\s/g, '')}`;
  return (
    <svg viewBox="0 0 48 48" aria-labelledby={labelId} {...props}>
      {title && <title id={labelId}>{title}</title>}
      {description && <desc>{description}</desc>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 0C10.745 0 0 10.745 0 24s10.745 24 24 24 24-10.745 24-24S37.255 0 24 0zm-5.277 11.277c1.366-.062 1.802-.077 5.278-.077h-.004c3.478 0 3.912.015 5.278.077 1.362.063 2.293.279 3.109.595a6.256 6.256 0 012.267 1.476 6.281 6.281 0 011.477 2.268c.315.813.53 1.744.595 3.106.061 1.366.077 1.802.077 5.278 0 3.476-.016 3.912-.077 5.277-.064 1.362-.28 2.293-.595 3.107a6.283 6.283 0 01-1.477 2.267 6.263 6.263 0 01-2.266 1.477c-.815.317-1.746.532-3.108.595-1.366.062-1.8.077-5.277.077-3.476 0-3.912-.015-5.278-.077-1.362-.063-2.293-.278-3.107-.595a6.268 6.268 0 01-2.267-1.477 6.271 6.271 0 01-1.476-2.268c-.316-.814-.532-1.744-.595-3.106-.061-1.366-.077-1.8-.077-5.277 0-3.476.016-3.913.077-5.278.061-1.362.277-2.293.595-3.107a6.284 6.284 0 011.477-2.267 6.268 6.268 0 012.268-1.476c.813-.316 1.744-.532 3.106-.595z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.853 13.507h1.148c3.418 0 3.823.012 5.173.073 1.248.057 1.925.266 2.376.441.598.232 1.023.51 1.471.957.448.449.725.875.958 1.473.175.45.384 1.127.44 2.375.062 1.35.075 1.755.075 5.171 0 3.416-.013 3.822-.074 5.171-.057 1.248-.266 1.925-.441 2.376a3.958 3.958 0 01-.958 1.47 3.959 3.959 0 01-1.47.958c-.451.176-1.13.384-2.377.44-1.35.062-1.755.075-5.173.075s-3.823-.013-5.172-.074c-1.248-.058-1.925-.266-2.377-.441a3.965 3.965 0 01-1.472-.958 3.968 3.968 0 01-.958-1.471c-.175-.45-.384-1.128-.44-2.376-.062-1.35-.074-1.755-.074-5.173s.012-3.821.073-5.17c.058-1.249.266-1.926.441-2.377.232-.598.51-1.024.958-1.472a3.972 3.972 0 011.472-.958c.451-.176 1.129-.384 2.377-.441 1.18-.054 1.638-.07 4.024-.072v.003zm7.98 2.125a1.536 1.536 0 100 3.073 1.536 1.536 0 000-3.073zM17.429 24a6.574 6.574 0 1113.147 0 6.574 6.574 0 01-13.147 0z"
        fill="#000"
      />
      <path d="M24.001 19.733a4.267 4.267 0 110 8.534 4.267 4.267 0 010-8.534z" fill="#000" />
    </svg>
  );
}

export default Instagram;
