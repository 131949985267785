import styled from 'styled-components';
import Logo from '../logo';
import Navigation from '../navigation';
import SocialNetworks from '../social-networks';

export const HeadWrapper = styled.header`
  display: block;
  min-height: 100px;
  position: relative;
  ${({ theme }) =>
    theme.breakpoints.under_xl(`
      min-height: 130px;
  `)}
`;

export const StyledNavigation = styled(Navigation)`
  position: absolute;
  bottom: 0;
  ${({ theme }) =>
    theme.breakpoints.under_xl(`
      width: 100%;
      justify-content: center;
  `)}
`;
export const StyledLogo = styled(Logo)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  ${({ theme }) =>
    theme.breakpoints.under_xl(`
      bottom: 35px;
  `)}
`;
export const StyledSocials = styled(SocialNetworks)`
  position: absolute;
  right: 0;
  bottom: 0;
  ${({ theme }) =>
    theme.breakpoints.under_m(`
      position: relative;
      justify-content: flex-end;
      margin-top: 20px;
  `)}
`;
