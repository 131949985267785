import * as React from 'react';
import { HeadWrapper, StyledLogo, StyledNavigation, StyledSocials } from './style';

export interface IHeaderProps {
  instagramUrl?: string;
  className?: string;
}

const Header: React.FC<IHeaderProps> = ({ instagramUrl, className }) => {
  return (
    <HeadWrapper className={className}>
      <StyledLogo width="95px" />
      <StyledNavigation />
      <StyledSocials instagramUrl={instagramUrl} />
    </HeadWrapper>
  );
};

export default Header;
