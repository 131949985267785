import * as React from 'react';
import Instagram from '../icons/instagram';
import { Wrapper, SocialLink } from './style';

export interface ISocialNetworksProps {
  instagramUrl?: string;
  className?: string;
}

const SocialNetworks: React.FC<ISocialNetworksProps> = ({ instagramUrl, className }) => {
  return (
    <Wrapper className={className}>
      {instagramUrl && (
        <SocialLink href={instagramUrl} target="_blank" rel="noreferrer">
          <Instagram width="24px" title="noun graphisme instgram" />
        </SocialLink>
      )}
    </Wrapper>
  );
};

export default SocialNetworks;
